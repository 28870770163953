import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import { Link } from 'gatsby';
import Icon from '../../../components/Icon';
import MultiVideoPlayer from '../../../components/MultiVideoPlayer';

const InsideTheWorldOfScience = () => (
  <Layout
    title="Inside the World of Science"
    className="science-starters virtual-field-trip has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../virtual-field-trips">Virtual Field Trips</Link>
          </li>
          <li className="breadcrumb active">Inside the World of Science</li>
        </ul>
        <Row>
          <Column size={10} offset={1}>
            <h1 className="page-title">Inside the World of Science</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo de-vid">
              <Link to="../how-nature-inspires-3M-science" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: 'a8bda77d-9ad0-41df-af05-02315193874e',
                    thumbnail: 'VFT-inside-the-world-of-science-full.jpeg',
                    title: 'Full VFT: Inside the World of 3M Science',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '5fd914d6-871c-46fd-aa89-9587de374f98',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-2.jpg',
                    title: 'Chapter 1: Welcome to the 3M Innovation Center',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '8a194a36-716a-4d65-90b1-42ce6fd1b596',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-3.jpg',
                    title: 'Chapter 2: 3M™ Novec™ Fluid',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'e5b6461b-0dc5-4eef-9454-64fef093fd25',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-4.jpg',
                    title: 'Chapter 3: Films and Adhesives',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '9facd862-1501-4c12-9914-032fbfbfbd35',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-5.jpg',
                    title: 'Chapter 4: Lithium-ion Batteries',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '3bb058f7-4a95-4167-92c6-ac2fefed0e02',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-6.jpg',
                    title: 'Chapter 5: Solar Panels',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '652730bf-dc02-4ccc-ba12-d754f24d2b1e',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-7.jpg',
                    title: 'Chapter 6: The 3M Young Scientist Challenge',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '467a189d-f07b-4e26-a189-dfb9e047988c',
                    poster: 'VFT-Inside-the-World-of-Science-Chapter-1.jpg',
                    title: 'Chapter 7: Meet the Scientists',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../how-nature-inspires-3M-science" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row>
          <Column size={8} offset={1}>
            <h2>About</h2>
            <p>
              Bring students along for a tour of this cutting-edge innovation
              center, where leading scientific experts are working to improve
              lives worldwide by harnessing STEM problem-solving. Introduce
              students to diverse 3M employees to learn how they turn their
              passion into real-world solutions.
            </p>
            <ul>
              <li>
                What experiment would you try if you could take control at a 3M
                Innovation Center?
              </li>
              <li>
                Would you be more interested in developing new products or
                managing processes and people?
              </li>
              <li>
                Did you know supercomputers generate so much heat they need to
                be cooled with a special fluid?
              </li>
              <li>
                Are you more interested in mechanical engineering, product
                development, or executive leadership?{' '}
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default InsideTheWorldOfScience;
